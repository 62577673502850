@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.hero {
	width: 100%;
	position: relative;
}

.xmasBanner {
	padding: 15px 10px 5px 10px;
	background-color: $purple;
	width: 100%;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0;
	color: $white;
	text-align: center;
	overflow: hidden;

	.xmasBannerIcon {
		position: absolute;

		&.iconSantaHat {
			top: -13px;
			left: 15px;

			@include tablet() {
				left: 60px;
			}
		}

		&.iconSnowFlake {
			right: 30px;
			top: -20px;

			@include tablet() {
				right: 60px;
			}
		}

		&.iconPresent {
			left: -15px;
			bottom: -15px;
			transform: rotate(20deg);
		}

		&.iconBauble {
			right: -20px;
			bottom: -20px;
			transform: rotate(-20deg);
		}
	}

	p {
		margin: 0 auto;
		color: $white;
		font-weight: $bold-base;

		span {
			display: block;
			color: $yellow;

			@include tablet() {
				display: inline;
			}
		}
	}

	a,
	button {
		color: $white;

		&:hover {
			color: $white;
		}
	}
}

.heroContent {
	position: relative;
	z-index: 2;
	text-align: center;
	padding-top: 40px;
	padding-bottom: 260px;
	grid-column: 1 / span 6;

	@include tablet() {
		text-align: left;
		padding-bottom: 60px;
		padding-top: 60px;
		grid-column: 1 / span 7;
	}

	@include desktop() {
		padding-top: 130px;
		padding-bottom: 130px;
		grid-column: 1 / span 6;
	}

	&.heroContentLight {
		h1,
		p,
		.trustpilot {
			color: $white;
		}
	}

	h1 {
		font-size: 3.4rem;
		line-height: 3.6rem;
		margin-bottom: 20px;

		@include tablet() {
			font-size: 4rem;
			line-height: 4.2rem;
			text-align: left;
		}

		@include desktop() {
			font-size: 5rem;
			line-height: 5.2rem;
		}
	}

	p {
		margin-left: auto;
		margin-right: auto;
		max-width: 500px;

		@include tablet() {
			margin-left: 0;
		}

		@include desktop() {
			max-width: none;
		}
	}
}

.backgroundVideo {
	width: 100%;
	position: relative;
	overflow: hidden;
	z-index: 0;

	video {
		width: 100%;
	}
}

.backgroundImage {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
	z-index: 0;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;

		@include tablet() {
			width: 140%;
			transform: translate(-15%, 0%);
		}

		@include desktop() {
			width: 110%;
			height: 105%;
			transform: translate(-5%, 0%);
		}

		@include desktopLarge() {
			width: 100%;
			height: 100%;
			transform: none;
		}
	}
}

.trustpilot {
	font-size: 1.6rem;
	margin-top: 20px;
	display: block;
	text-align: left;
	width: 100%;
	position: relative;
	left: -5px;

	@include tablet() {
		width: 230px;
	}

	div {
		text-align: left !important;
	}
}

.widgetTest {
	min-height: 171px;

	@include desktop() {
		min-height: 113px;
	}
}

.testNotLoaded {
	opacity: 0;
	transition: opacity 0.4s;
}
