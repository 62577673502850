@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.trustpilot {
	width: 100%;
	margin-top: 15px;
	position: relative;
	opacity: 1;
	transition: opacity 0.4s 1s;
	max-width: 100vw;

	@include tablet() {
		width: 230px;
		left: -5px;
	}

	&.center {
		margin-bottom: 20px;
		margin-top: 0;

		@include tablet() {
			width: 230px;
			margin-left: auto;
			margin-right: auto;
			left: 0;
		}
	}

	&.loading {
		opacity: 0;
		transition: opacity 1s;
	}

	&.showReviewCount {
		width: 100%;
		margin: 0;
		padding: $gutter 0 $gutter;
	}
}
