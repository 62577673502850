@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.widget {
	width: 100%;
	text-align: center;

	@include tablet() {
		text-align: left;
	}

	&.widgetLight p,
	&.widgetLight label {
		@include tablet() {
			color: $off-white;
		}
	}

	&.widgetCustom form {
		flex-direction: column;
		max-width: 400px;
		margin: 0 auto;

		@include tablet() {
			margin: 0;
			max-width: 400px;
		}

		@include desktop() {
			flex-direction: row;
			max-width: none;
		}

		> * {
			width: 100%;
			@include desktop() {
				width: auto;
			}
		}
		> *:first-child {
			margin: 0;
			gap: 10px;
			@include desktop() {
				width: 220px;
			}
		}
	}

	p {
		font-weight: $bold-base;
		font-size: 2rem;
		margin-bottom: 10px;
	}

	form {
		display: flex;
		align-items: flex-end;
		gap: 10px;
		flex-wrap: wrap;

		@include desktop() {
			flex-wrap: nowrap;
		}

		> *:first-child {
			@include tablet() {
				min-width: 300px;
			}
		}

		> * {
			width: 100%;
			@include tablet() {
				width: auto;
			}
		}

		button {
			width: 100% !important;

			@include tablet() {
				width: auto !important;
			}
		}

		.twoFields {
			display: flex;
			gap: 10px;
			input {
				max-width: 150px;
			}
		}
	}

	.widgetError {
		position: absolute;
		margin-top: 10px;
		z-index: 2;
	}

	.infoBox {
		margin-top: 10px;
		padding: 10px;
		border-radius: 0px;
		background-color: $off-white;
		position: absolute;
		z-index: 2;
		display: flex;
		gap: 10px;
		align-items: flex-start;
		text-align: left;

		h4 {
			margin-bottom: 5px;
			color: $deep-green !important;
		}

		p {
			color: $deep-green !important;
			font-weight: normal;
			font-size: 1.6rem;
			margin-bottom: 0;
		}
	}
}
