@use "sass:math";

@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.radioItem {
	transition: 0.4s ease-in-out;
	background: $white;
	border: 1px solid $deep-green;
	border-radius: 5px;
	cursor: pointer;
	min-width: 0;

	@include mobileLarge() {
		margin: 0 $mobile-gutter;
	}

	&.selected {
		background: $deep-green;

		& .label,
		p,
		h4,
		h3 {
			color: $white;
		}
	}

	&.noIcon {
		& .inputArea {
			text-align: center;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: $gutter;

			& .input {
				display: block;
				margin: 0 auto 12px;
			}

			& .label {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	&.noCircle {
		grid-column: span 3 !important;
		.inputArea {
			padding: 13px 10px;
			.input {
				display: none;
			}
			.label {
				margin-top: 0;
			}
		}
	}

	&.dual {
		margin: 0;

		&:first-child {
			grid-column: 1 / span 3;
		}

		&:last-child {
			grid-column: 4 / span 3;
		}

		@include desktop() {
			&:first-child {
				grid-column: 2 / span 2;
			}

			&:last-child {
				grid-column: 4 / span 2;
			}
		}
	}

	&.trio {
		margin: 0;

		&:nth-child(1) {
			grid-column: 1 / span 2;
		}

		&:nth-child(2) {
			grid-column: 3 / span 2;
		}

		&:nth-child(3) {
			grid-column: 5 / span 2;
		}
	}

	&.twoPerRow {
		margin: 0;

		//odd elements
		&:nth-child(odd) {
			grid-column: 1 / span 3;
		}

		//even elements
		&:nth-child(even) {
			grid-column: 4 / span 3;
		}
	}

	@include desktop() {
		&.threePerRowDesktop {
			&:nth-child(3n + 1) {
				grid-column: 1 / span 2;
			}

			&:nth-child(3n + 2) {
				grid-column: 3 / span 2;
			}

			&:nth-child(3n) {
				grid-column: 5 / span 2;
			}
		}
	}

	@include tablet() {
		margin: 0 $tablet-gutter;
		$desktopSize: math.div(100, 3);
		$gutterSize: 2 * $desktop-gutter;
		flex-basis: calc((100% - #{$desktopSize}) - #{$gutterSize});

		&:nth-child(1) {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@include tabletLarge() {
		margin: 0 $tablet-gutter;
		$desktopSize: 100 * 0.25;
		$gutterSize: 2 * $desktop-gutter;
		flex-basis: calc((100% - #{$desktopSize}) - #{$gutterSize});

		&:nth-child(1) {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@include desktop() {
		margin: 0 $desktop-gutter;
		$desktopSize: math.div(100, 6);
		$gutterSize: 2 * $desktop-gutter;
		flex-basis: calc((100% - #{$desktopSize}) - #{$gutterSize});
	}
}

.inputArea {
	width: 100%;
	padding: $gutter;

	@include tabletLarge() {
		padding: $gutter;
	}

	.input {
		height: 18px;
		width: 18px;
		position: relative;
		margin: 0 auto $gutter * 0.5;

		@include tabletLarge() {
			width: 26px;
			height: 26px;
		}

		& .statusOrb {
			display: block;
			height: 100%;
			width: 100%;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			&::before {
				content: "";
				visibility: visible;
				position: absolute;
				opacity: 1;
				width: 14px;
				height: 14px;
				top: 50%;
				left: 50%;
				background-color: transparent;
				border: 2px solid $deep-green;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				transition: all 0.3s ease-in-out;

				@include tabletLarge() {
					width: 20px;
					height: 20px;
				}
			}

			&::after {
				opacity: 0;
				content: "";
				visibility: visible;
				position: absolute;
				width: 10px;
				height: 10px;
				top: 50%;
				left: 50%;
				background-color: $white;
				border-radius: 100%;
				transform: translate(-50%, -50%);
				transition: all 0.4s ease-in-out;

				@include tabletLarge() {
					width: 13px;
					height: 13px;
				}
			}
		}

		& > input[type="radio"] {
			// visibility: hidden;
			opacity: 0;
			user-select: none;
			pointer-events: none;
			box-shadow: none !important;

			&:checked + .statusOrb {
				&::before {
					background-color: $deep-green;
					border: 2px solid $white;
				}

				&::after {
					opacity: 1;
				}
			}
		}
	}

	.label {
		font-weight: normal;
		position: relative;
		line-height: 2rem;
		font-size: 1.6rem;
		user-select: none;
		color: $deep-green;
		transition: 0.4s ease-in-out;
		margin-top: $gutter * 0.5;

		& .caption {
			font-size: 1.4rem;
			line-height: 1.6reml;
		}
	}
}

.iconArea {
	width: 100%;
	padding: 20px 20px 5px;
}

.icon {
	color: $yellow;

	svg {
		height: 48px;
		width: 48px;
	}

	path {
		fill: currentColor !important;
	}
}

.trio {
	&.toggleItem {
		flex-basis: calc(100% / 3);
		width: calc(100% / 3);
	}
}

.toggleItem {
	height: 100%;
	box-shadow: none;
	margin: 0;
	display: flex;
	flex-direction: column;
	background: transparent;
	cursor: pointer;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 25%;
	width: 25%;

	&.dual {
		width: 50%;
		flex-basis: 50%;
	}

	&.narrow .inputArea {
		padding: 0px 10px 5px;
		@include tablet() {
			padding: 0px 10px 15px;
		}
	}

	&.toggleSelected {
		.label {
			color: $white;
		}
	}

	.iconArea {
		width: 100%;
		padding: 10px 5px 10px;
		z-index: 2;
		position: relative;
		flex-basis: 50%;

		@include tabletLarge() {
			padding: 10px 5px 10px;
		}
	}

	.inputArea {
		background-color: transparent;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		flex-basis: 50%;
		padding: 20px 8px 10px;

		@include tabletLarge() {
			padding: 20px 20px 10px;
		}

		.input {
			opacity: 0;
			position: absolute;
		}

		.label {
			transition: color 0.4s;
			font-size: 1.4rem;
			max-width: 100%;

			@include respond-min($xxs) {
				font-size: 1.6rem;
			}

			& span {
				white-space: pre-wrap;
				/* white-space: nowrap; */
				max-width: 100%;
				display: block;
			}
		}
	}
}
