@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.quotes {
	h2 {
		font-size: 3rem;
		line-height: 3.4rem;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}
}

.quote {
	background-color: $deep-green;
	display: flex;
	flex-wrap: wrap;
	color: $white;
	grid-column: span 6;

	@include desktop() {
		grid-column: span 6;
	}

	.quoteImage {
		width: 100%;
		height: 300px;

		@include tablet() {
			width: 40%;
			height: 100%;
		}

		@include desktop() {
			width: 35%;
		}

		> div {
			width: 100%;
			height: 100%;
		}

		img {
			object-fit: cover;
		}
	}

	.quoteContent {
		padding: 20px;
		color: $white;
		align-self: center;
		width: 100%;

		@include tablet() {
			width: 60%;
		}

		@include desktop() {
			width: 65%;
		}

		p {
			color: $white;
		}

		> p {
			margin-bottom: 0;
		}
	}
}

.singleQuote {
	@include desktop() {
		grid-column: 3 / span 8;
	}
}

// BORDER
.border-zigzag-bottom {
	position: relative;
	&:after {
		left: 0;
		content: "";
		position: absolute;
		height: 25px;
		top: calc(100% - 7px);
		width: 100%;
		background-repeat: repeat;
		background-size: 25px 30px;
		z-index: 2;
		transform: rotate(180deg);
		background-image: url("/images/zig-zag-off-white-i.svg");
	}
}
