@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.helpCard {
	background-color: $white;
	padding: $gutter;
	text-align: center;

	@include tablet() {
		padding: $gutter;
	}

	&.helpCardMoving {
		margin: 0;
		position: relative;
		transition: left 0.4s;
		width: 66.6%;

		@include mobileLarge() {
			width: 57%;
		}

		@include tablet() {
			width: 50%;
		}

		@include tabletLarge() {
			width: 40%;
		}
	}

	p {
		font-size: 1.4rem;
		line-height: 1.3;
		color: deep-green;
		margin-bottom: 10px;
	}

	h4 {
		color: $deep-green;
		margin-bottom: 10px;
	}

	div {
		color: $deep-green;
		margin-top: 10px;
	}

	*:last-child {
		margin-bottom: 0;
	}
}
